import React from "react";
import moment from 'moment';
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentSection from "../components/content/section";

// import Chart from 'chart.js';

export default class Dashboardd extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTime: new Date().getTime(),
			rebaseTimer: '...'
		};
	}
	componentDidMount() {
		this.timerID = setInterval(
			() => this.tick(), 1000
		);
	}
	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	tick() {
		function pad(num) {
			return ("0" + parseInt(num)).substr(-2);
		}
		const now = new Date().getTime() - moment().utcOffset() * 60 * 1000;
		const rebaseTime = new Date(1597582800000 - moment().utcOffset() * 60 * 1000 + Math.ceil((now - 1597582800000 + moment().utcOffset() * 60 * 1000) / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000)).getTime();
		console.log(now);
		console.log(Math.ceil((now - 1597582800000 + moment().utcOffset() * 60 * 1000)) / (24 * 60 * 60 * 1000));
		console.log(rebaseTime);

		this.setState({
			currentTime: now
		});

		const remain = ((rebaseTime - now) / 1000);
		var hh = pad((remain / 60 / 60) % 60);
		var mm = pad((remain / 60) % 60);
		var ss = pad(remain % 60);
		this.setState({
			rebaseTimer: hh + ":" + mm + ":" + ss
		})
	}
	render() {
		return (
			<Layout>
				<SEO title="Ducat Dashboard" />
				<div className="dashboard">
					<ContentSection title="Rebase Information">
						<div className="row">
							<div className="col-4">
								<div className="card">
									<div className="icon">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-clock-history" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										  <path fill-rule="evenodd" d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
										  <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
										  <path fill-rule="evenodd" d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
										</svg>
									</div>
									<div className="content">
										<div className="label">Next Rebase</div>
										<div className="value">{this.state.rebaseTimer}</div>
									</div>
								</div>
							</div>
							<div className="col-4">
								<div className="card">
									<div className="icon">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calculator" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										  <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 .5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zM4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM4 12.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zM7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM7 9.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM10 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-1z"/>
										</svg>
									</div>
									<div className="content">
										<div className="label">Oracle Price</div>
										<div className="value">$1.05</div>
									</div>
								</div>
							</div>
							<div className="col-4">
								<div className="card">
									<div className="icon">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-bullseye" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
										  <path fill-rule="evenodd" d="M8 13A5 5 0 1 0 8 3a5 5 0 0 0 0 10zm0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
										  <path fill-rule="evenodd" d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"/>
										  <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
										</svg>
									</div>
									<div className="content">
										<div className="label">Price Target</div>
										<div className="value">$1.01</div>
									</div>
								</div>
							</div>
						</div>
					</ContentSection>
				</div>
			</Layout>
		)
	}
}